<template>
<ShowFilterSearchOrderFull/>
</template>

<script>
import FilterSearchOrderFull from "@/components/order/FilterSearchOrderFull";
import ShowFilterSearchOrderFull from "@/components/order/ShowFilterSearchOrderFull";
export default {
  name: "FilterTemporal",
  components: {
    ShowFilterSearchOrderFull,
    FilterSearchOrderFull
  },
  methods: {
    exit(){
      this.$router.push('/captura-de-resultados')
    }
  }
}
</script>

<style scoped>

</style>